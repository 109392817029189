import React, { useState } from 'react';
import { Typography } from '@mui/material';
import MFA from '../../../../context/mfa';
import UserInput from './functions/userInput';
type Profile = {
  address: string | null;
  billingCustomerId: number | null;
  billingID: string | null;
  companyName: string | null;
  country: string | null;
  created: bigint | null;
  createdAt: string;
  customerCode: string;
  email: string;
  id: number;
  name: string | null;
  phone: string | null;
  role: string;
  uid: string;
  updatedAt: string;
  userType: string | null;
  vatNumber: string | null;
};

type UserInformationProps = {
  profile: Profile;
};

const UserInformation = ({ profile }: UserInformationProps) => {
  const isAdmin = profile.role === 'ADMIN';
  return (
    <div className="lg:p-6 p-1 w-full justify-center items-center gap-8 flex flex-col">
      <Typography
        sx={{
          fontFamily: 'poppins',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: 'almost-black',
        }}
      >
        Minun tiedot
      </Typography>
      <div className=" justify-center flex flex-col w-full">
        <UserInput label="Nimi" value={profile.name} target="name" id={profile.id} />
        <UserInput label="Sähköposti" value={profile.email} disabled target="email" id={profile.id} />
        <UserInput label="Osoite" value={profile.address} target="address" id={profile.id} />

        <UserInput label="Yritys" value={profile.companyName} target="companyName" id={profile.id} />
        <UserInput label="Y-Tunnus" value={profile.vatNumber} target="vatNumber" id={profile.id} />

        <UserInput label="Puhelin" value={profile.phone} target="phone" id={profile.id} />
      </div>
      {/* ENABLE MFA */}
      {isAdmin && (
        <div className="flex flex-row justify-between gap-2 items-start w-full">
          <Typography
            sx={{
              fontFamily: 'poppins',
              fontSize: '1rem',
              fontWeight: 600,
              color: 'almost-black',
            }}
          >
            Kaksivaiheinen tunnistautuminen
          </Typography>
          <div id="recaptcha-container"></div>
          <button onClick={() => MFA.addPhoneNumberFor2FA(profile)} className="bg-blueBackground text-sm  p-2 rounded-3xl hover:bg-primary hover:scale-110 ease-in-out duration-300">
            Aktivoi 2FA
          </button>
        </div>
      )}
    </div>
  );
};
export default UserInformation;
