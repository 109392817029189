import React from 'react';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Cameras from './cameras';
import Remoteusers from './remoteusers';
import { convertTimestamp } from '../functions/convertTime';
import DownloadRut from '../../cameraManagement/downloadRut';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { useApi } from '../../../context/ApiCalls';
import DownloadGDPR from './downloadGDPR';

type MediaSize = {
  mobile: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
};
const sxCell = {
  fontWeight: 600,
  fontFamily: 'open-sans',
  fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem ' },
  color: '#5c5c5c',
};

export function SiteRow({
  site,
  handleUpdate,
  setHandleUpdate,
  handeRutGuide,
  errorCameras,
  mediaSize,
}: {
  site: any;
  handleUpdate: number;
  setHandleUpdate: any;
  handeRutGuide: any;
  errorCameras: string[];
  mediaSize: MediaSize;
}) {
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const { deleteCamera, deleteRemoteUser } = useApi();

  const handleDelete = async ({ id, type }: { id: any; type: string }) => {
    if (type === 'camera') {
      const response = await deleteCamera({
        camera: id,
        sub: site.subscription,
      });
      if (response?.status === 200) {
        // refresh data
        setHandleUpdate(handleUpdate + 1);
      } else {
        // error
      }
    } else if (type === 'user') {
      const response = await deleteRemoteUser(id);
      if (response?.status === 200) {
        // refresh data
        setHandleUpdate(handleUpdate + 1);
      } else {
        // error
      }
    }
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {
            borderBottom: 'unset',
            backgroundColor: '#E1EFFF',
            color: 'black',
            padding: { xs: '2', sm: '6', md: '8' },
            textAlign: 'center',
          },
        }}
      >
        <TableCell
          sx={{
            textAlign: 'center',
            margin: '0',
            padding: '0',
          }}
        >
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={sxCell} component="th" scope="row">
          {site.name}
        </TableCell>
        {(mediaSize.md || mediaSize.lg || mediaSize.xl) && (
          <TableCell sx={sxCell} align="right">
            {site.address}
          </TableCell>
        )}
        <TableCell sx={sxCell} align="right">
          {site.contactPerson}
        </TableCell>
        <TableCell sx={sxCell} align="right">
          {site.phone}
        </TableCell>
        <TableCell sx={sxCell} align="center">
          <div className="flex justify-center items-center gap-2 flex-wrap ">
            <DownloadRut mediaSize={mediaSize} imageurl={site} />
            <DownloadGDPR mediaSize={mediaSize} gdprUrl={site.gdprUrl} />
            {(mediaSize.xl || mediaSize.lg) && (
              <HelpCenterIcon onClick={() => handeRutGuide(site)} className="mx-2 color-white cursor-pointer hover:scale-110 ease-in-out duration-300" sx={{ fontSize: 30, color: '#43687e' }} />
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* Kamerat */}
            <Cameras
              site={site}
              edit={edit}
              setEdit={setEdit}
              mediaSize={mediaSize}
              handleDelete={handleDelete}
              convertTimestamp={convertTimestamp}
              handleUpdate={handleUpdate}
              setHandleUpdate={setHandleUpdate}
              errorCameras={errorCameras}
            />

            {/* Etäkäyttäjät */}
            <Remoteusers site={site} edit={edit} mediaSize={mediaSize} handleDelete={handleDelete} handleUpdate={handleUpdate} setHandleUpdate={setHandleUpdate} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
