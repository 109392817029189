import React, { useEffect, useState } from 'react';
import Modal from '../../components/modal/modal';
import { useAuth } from '../../context/authContext';
import { useApi } from '../../context/ApiCalls';
import CreateInvintation from './createInvintation';
import { Button } from '@mui/material';
const Table = ({ clients }: any) => {
  if (clients.length === 0) {
    return <div>Loading...</div>;
  }
  const trs = ['id', 'Yritys', 'Viite', 'Sähköposti', 'Puhelin', 'Osoite', 'Y-Tunnus'];

  return (
    <div>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {trs.map((tr, index) => {
              return (
                <th className="py-3 px-6 " key={index}>
                  {tr}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {clients.resellerClients.map((client: any) => {
            return (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 " key={client.id}>
                <td className="py-4 px-6">{client.billingCustomerId}</td>
                <td className="py-4 px-6">{client.companyName}</td>
                <td className="py-4 px-6">{client.customerCode}</td>
                <td className="py-4 px-6">{client.email}</td>
                <td className="py-4 px-6">{client.phone}</td>
                <td className="py-4 px-6">{client.address}</td>
                <td className="py-4 px-6">{client.vatNumber}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ResellerMain = () => {
  const [openModal, setOpenModal] = useState(false);
  const [clients, setClients] = useState<[] | null>(null);
  const { user } = useAuth();
  const { resellerClients } = useApi();
  useEffect(() => {
    async function fetchResellerClients() {
      const clients = await resellerClients({ user: user });
      setClients(clients);
    }
    fetchResellerClients();
  }, [user, resellerClients]);

  if (!clients) {
    return <div className="flex w-full h-full items-center justify-center text-large">Loading...</div>;
  }

  if (clients.length === 0) {
    return <div className="flex w-full h-full items-center justify-center text-large gap-5">Ei vielä jälleenmyyjiä!</div>;
  }
  return (
    <div className="w-full md:p-8">
      <Modal
        title="Lisää jälleenmyyjä"
        show={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <CreateInvintation setOpenModal={setOpenModal} />
      </Modal>
      <div
        className=" w-full flex justify-end
       items-start mb-4"
      >
        <div className=" hover:scale-105 hover:animate-pulse ease-in-out duration-300">
          <Button
            sx={{
              borderRadius: 6,
              padding: '8px',
            }}
            variant="contained"
            onClick={() => setOpenModal(true)}
          >
            Lisää uusi Jälleenmyyjä +
          </Button>
        </div>
      </div>
      <div className="w-full">
        <Table clients={clients} />
      </div>
    </div>
  );
};

export default ResellerMain;
