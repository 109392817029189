import React, { createContext, ReactNode, useContext } from 'react';
import { useAuth } from './authContext';
// Define types for Camera, RemoteUser, Server, etc.
/* interface Camera {
  cameraName: string;
  cameraLocation: string;
}
 */
interface RemoteUser {
  name: string;
  email: string;
  phone: string;
}

interface ApiContextType {
  deleteCamera: (args: { camera: string; sub: number }) => Promise<Response | undefined>;
  deleteRemoteUser: (remoteUser: number) => Promise<Response | undefined>;
  addCamera: (args: { camera: any; siteID: number }) => Promise<Response | unknown>;
  addRemoteUser: (args: { remoteUser: RemoteUser; siteID: number }) => Promise<Response | boolean>;
  updateCamera: (camera: any) => Promise<Response | undefined>;
  resellerClients: (args: { user: string }) => Promise<any>; // Adjust as needed
  billingCustomer: () => Promise<any>; // Adjust as needed
  downloadFile: (url: string) => Promise<void>;
  getServers: () => Promise<any>; // Adjust as needed
  newServer: (server: any) => Promise<Response | undefined>; // Define server type if known
  updateServerLicense: (args: { serverId: number; newLicenseCount: number }) => Promise<Response | undefined>;
}
export const ApiContext = createContext<ApiContextType | null>(null);

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useAuth();

  const baseURL = process.env.REACT_APP_BACKEND_URL;

  const deleteCamera = async ({ camera, sub }: { camera: string; sub: number }): Promise<any> => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/delete-camera/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          camera: camera,
          subID: sub,
          uid: currentUser.uid,
        }),
      });

      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const deleteRemoteUser = async (remoteUser: number) => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/delete-remote-user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          remoteUser: remoteUser,
        }),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };
  const updateCamera = async (camera: any) => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/update-camera/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(camera),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const addCamera = async ({ camera, siteID }: { camera: { cameraName: string; cameraLocation: string }; siteID: number }): Promise<Response | unknown> => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/add-camera/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ camera, siteID }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const addRemoteUser = async ({ remoteUser, siteID }: { remoteUser: { name: string; email: string; phone: string }; siteID: number }) => {
    const token = await currentUser.getIdToken();

    try {
      const response = await fetch(baseURL + '/add-remote-user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ remoteUser, siteID }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const resellerClients = async ({ user }: any) => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/reseller-clients/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ user }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const billingCustomer = async () => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/billing-customer/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const billingCustomers = data.billingCustomers;
      return billingCustomers;
    } catch (error) {
      console.log(error);
    }
  };

  const getServers = async () => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/admin/get-servers', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const newServer = async (server: any) => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/admin/create-server', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ serverData: server }),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const updateServerLicense = async ({ serverId, newLicenseCount }: { serverId: number; newLicenseCount: number }) => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/admin/update-server-license', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ serverId, newLicenseCount }),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  async function downloadFile(url: string) {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/download`;
    const token = await currentUser.getIdToken();
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ url }),
    });

    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlBlob;
    link.download = 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const value: ApiContextType = {
    deleteCamera,
    deleteRemoteUser,
    addCamera,
    addRemoteUser,
    updateCamera,
    resellerClients,
    billingCustomer,
    downloadFile,
    getServers,
    newServer,
    updateServerLicense,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }

  return context;
};
