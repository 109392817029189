import React from 'react';
import { Drawer, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import { LogOut } from 'lucide-react'; // Ensure LogOut is properly imported
import { DashboardLinks } from '../navigationBar'; // Assuming this is imported correctly

const MobileNavBar = ({ dashboardLinks, user, logout }: { dashboardLinks: DashboardLinks[]; user: any; logout: () => void }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };
  const isAdmin = user?.role === 'ADMIN';
  const isReseller = user?.role === 'RESELLER';
  const isUser = user?.role === 'USER';
  const isBillingCustomer = user?.role === 'BILLINGCUSTOMER';
  return (
    <div className=" text-white font-bold sticky top-0 flex items-center justify-between px-4 py-3 z-10">
      {/* Branding or User Profile */}
      <div className="flex items-center space-x-2">
        {user?.avatar && <Avatar src={user.avatar} alt={user.name} />}
        <span>{user?.name || ''}</span>
      </div>

      {/* Menu Icon */}
      <IconButton onClick={toggleDrawer(true)} className="text-white">
        <MenuIcon />
      </IconButton>

      {/* Drawer (Side Menu) */}
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <div className="w-64 bg-primaryBlue h-full text-white">
          <div className="flex justify-between items-center p-4">
            <IconButton onClick={toggleDrawer(false)} className="text-white">
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {/* Home Link */}
            <ListItem
              button
              onClick={() => {
                setOpen(false);
                navigate('/home');
              }}
            >
              <ListItemIcon className="text-white">
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Etusivu" />
            </ListItem>

            {/* About Link */}
            <ListItem
              button
              onClick={() => {
                setOpen(false);
                navigate('/about');
              }}
            >
              <ListItemIcon className="text-white">
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Tietoa meistä" />
            </ListItem>
            {/* Contact Link */}
            <ListItem
              button
              onClick={() => {
                setOpen(false);
                navigate('/contact');
              }}
            >
              <ListItemIcon className="text-white">
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Ota yhteyttä" />
            </ListItem>

            {/* Render dashboard links based on user role */}
            {user &&
              !isReseller &&
              dashboardLinks.map((link) => (
                <ListItem
                  button
                  key={link.to}
                  onClick={() => {
                    navigate(link.to);
                    setOpen(false);
                  }}
                >
                  <ListItemIcon className="text-white">{link.icon}</ListItemIcon>
                  <ListItemText primary={link.text} />
                </ListItem>
              ))}

            {/* Conditional links for specific user roles */}
            {user?.role === 'RESELLER' && (
              <ListItem
                button
                onClick={() => {
                  navigate('/reseller');
                  setOpen(false);
                }}
              >
                <ListItemIcon className="text-white">
                  <MenuIcon />
                </ListItemIcon>
                <ListItemText primary="Hallintapaneeli" />
              </ListItem>
            )}

            {user?.role === 'ADMIN' && (
              <ListItem
                button
                onClick={() => {
                  navigate('/admin');
                  setOpen(false);
                }}
              >
                <ListItemIcon className="text-white">
                  <MenuIcon />
                </ListItemIcon>
                <ListItemText primary="Adminpaneeli" />
              </ListItem>
            )}

            <Divider />

            {/* Login/Logout links */}
            {user ? (
              <ListItem button onClick={handleLogout}>
                <ListItemIcon className="text-white">
                  <LogOut />
                </ListItemIcon>
                <ListItemText primary="Kirjaudu ulos" />
              </ListItem>
            ) : (
              <>
                <ListItem
                  button
                  onClick={() => {
                    navigate('/login');
                    setOpen(false);
                  }}
                >
                  <ListItemText primary="Kirjaudu sisään" />
                </ListItem>
                {/* <ListItem
                  button
                  onClick={() => {
                    navigate('/register');
                    setOpen(false);
                  }}
                >
                  <ListItemText primary="Rekisteröidy" />
                </ListItem> */}
              </>
            )}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileNavBar;
