import { PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, multiFactor } from 'firebase/auth';
import { auth } from '../firebase'; // Oletus, että Firebase Auth on alustettu
let recaptchaVerifier: RecaptchaVerifier | null = null;
// Luo näkymätön reCAPTCHA
const phoneAuthProvider = new PhoneAuthProvider(auth);
const setupInvisibleRecaptcha = () => {
  if (!recaptchaVerifier) {
    const containerElement = 'recaptcha-container'; // Näkymätön reCAPTCHA tarvitsee elementin, mutta sen ei tarvitse olla näkyvä.

    recaptchaVerifier = new RecaptchaVerifier(
      auth, // Firebase Auth -instanssi
      containerElement, // Säilö (ID) johon reCAPTCHA "renderöidään"
      {
        size: 'invisible', // Invisible reCAPTCHA
        callback: (response: any) => {
          // reCAPTCHA on ratkaistu
          console.log('Invisible reCAPTCHA ratkaistu', response);
          // Puhelinnumeron vahvistus voisi alkaa täällä
          verifyPhoneNumberFor2FA();
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired, resetting...');
          recaptchaVerifier = null; // Nollataan, jotta voidaan alustaa uudelleen tarvittaessa
        },
      }
    );

    // Varmista, että invisible reCAPTCHA renderöityy sivulle
    recaptchaVerifier.render().then((widgetId: any) => {
      console.log(`Invisible reCAPTCHA widget rendered with ID: ${widgetId}`);
    });
  } else {
    console.log('reCAPTCHA on jo alustettu, ei renderöidä uudelleen.');
  }
};

// Funktio, jolla lisätään puhelinnumero käyttäjän 2FA-profiiliin
const addPhoneNumberFor2FA = async (user: any) => {
  try {
    setupInvisibleRecaptcha(); // Varmista, että reCAPTCHA on alustettu

    // Pyydä käyttäjältä puhelinnumero
    const phoneNumber = window.prompt('Syötä puhelinnumerosi kansainvälisessä muodossa (esim. +358401234567):');
    if (!phoneNumber || !phoneNumber.startsWith('+')) {
      console.error('Virheellinen puhelinnumero. Käytä kansainvälistä muotoa.');
      return;
    }
    console.log(`Puhelinnumero: ${phoneNumber}`);

    if (recaptchaVerifier) {
      // reCAPTCHA on jo renderöity, voit käyttää sitä suoraan
      console.log('reCAPTCHA on jo renderöity, käytetään sitä.');
      recaptchaVerifier.verify().then(() => {
        // reCAPTCHA on ratkaistu, lähetä vahvistuskoodi
        console.log('reCAPTCHA ratkaistu, lähetetään vahvistuskoodi...');
        auth.languageCode = 'fi'; // Aseta kieli (valinnainen)

        if (!recaptchaVerifier) {
          console.error('reCAPTCHA ei ole alustettu oikein.');
          return;
        }
        return phoneAuthProvider.verifyPhoneNumber(phoneNumber, recaptchaVerifier);
      });
    } else {
      console.error('reCAPTCHA ei ole alustettu oikein.');
      return;
    }
  } catch (error) {
    console.error('Virhe lisättäessä puhelinnumeroa 2FA:ta varten:', error);
  }
};
// Funktio puhelinnumeron vahvistamiseen ja MFA-rekisteröintiin
// Funktio puhelinnumeron vahvistamiseen ja MFA-rekisteröintiin
async function verifyPhoneNumberFor2FA() {
  try {
    console.log('Vahvistetaan puhelinnumeroa ja lisätään 2FA-profiiliin...');

    // Kysytään käyttäjän puhelinnumero (tässä oletetaan, että se on jo syötetty `addPhoneNumberFor2FA`-funktiossa)
    const phoneNumber = window.prompt('Syötä puhelinnumerosi uudelleen vahvistusta varten:');
    if (!phoneNumber) {
      console.error('Puhelinnumero puuttuu.');
      return;
    }

    // Vahvista puhelinnumero ja lähetä koodi
    if (recaptchaVerifier) {
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneNumber, recaptchaVerifier);

      // Pyydä käyttäjältä tekstiviestillä saama koodi
      const verificationCode = window.prompt('Syötä tekstiviestillä saama vahvistuskoodi:');
      if (!verificationCode) {
        console.error('Vahvistuskoodi puuttuu.');
        return;
      }

      // Luo MFA-assertio
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

      // Lisää puhelinnumero käyttäjän 2FA-profiiliin
      const user = auth.currentUser; // Hae kirjautunut käyttäjä
      if (user) {
        await multiFactor(user).enroll(multiFactorAssertion, 'Puhelinnumero 2FA:ta varten');
        console.log('Puhelinnumero lisätty 2FA:ta varten onnistuneesti.');
      } else {
        console.error('Käyttäjä ei ole kirjautuneena.');
      }
    } else {
      console.error('reCAPTCHA-verifieriä ei ole alustettu oikein.');
    }
  } catch (error) {
    console.error('Virhe puhelinnumeron vahvistuksessa:', error);
  }
}
// Function to set up invisible reCAPTCHA
const setupInvisibleRecaptchaForLogin = (): RecaptchaVerifier | null => {
  console.log('Setting up invisible reCAPTCHA for login...');
  console.log(recaptchaVerifier);
  if (!recaptchaVerifier) {
    recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container-login', {
      size: 'invisible',
      callback: (response: any) => {
        console.log('reCAPTCHA solved.');
      },
    });

    // Ensure the invisible reCAPTCHA is rendered on the page
    recaptchaVerifier.render().then((widgetId: any) => {
      console.log(`Invisible reCAPTCHA widget rendered with ID: ${widgetId}`);
    });
  }

  return recaptchaVerifier;
};

const MFA = { setupInvisibleRecaptchaForLogin, addPhoneNumberFor2FA, verifyPhoneNumberFor2FA, setupInvisibleRecaptcha };
export default MFA;
