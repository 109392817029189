import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, Button, TableHead, TableRow, Typography } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../../../components/modal/modal';
import AddNewCamera from './addNewCamera';
import CameraConfiguration from '../../../components/guides/cameraConfigurration';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import PtzCheckBox from './ptzCheckBox';
import ConfirmationModal from './confirmationModal';
const Cameras = ({ edit, setEdit, site, convertTimestamp, handleDelete, handleUpdate, setHandleUpdate, mediaSize, errorCameras }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [configurationOpen, setConfigurationOpen] = useState(false);
  const [seleteCamera, setDeleteCamera] = useState({ id: '', type: '', name: '' });
  const [camera, setCamera] = useState({});
  const closeModal = () => {
    setOpenModal(false);
  };

  const checkIfCameraIsError = (camera: any) => {
    return errorCameras.includes(camera.id);
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleDeleteConfirmation = ({ id, type, camera }: { id: any; type: string; camera: any }) => {
    setDeleteCamera({ id, type, name: camera.name });
    setIsOpen(true);
  };

  const sx = {
    fontFamily: 'open-sans',
    fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem ' },
    fontWeight: 600,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const rowsx = {
    fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem ' },
    fontWeight: 500,
    fontFamily: 'open-sans',
    textAlign: 'center',
  };

  return (
    <>
      <Modal show={openModal} onClose={closeModal} title="Lisää kamera">
        <AddNewCamera site={site} closeModal={closeModal} handleUpdate={handleUpdate} setHandleUpdate={setHandleUpdate} />
      </Modal>
      <ConfirmationModal
        isOpen={isOpen}
        title={`Poista kamera ${seleteCamera.name}`}
        message="Haluatko varmasti poistaa kameran? Kameran poistaminen poistaa kaikki kameran tallentamat tiedot ja kuvamateriaalin. Tätä toimintoa ei voi peruuttaa."
        onConfirm={() => {
          handleDelete(seleteCamera);
          setIsOpen(false);
          setDeleteCamera({ id: '', type: '', name: '' });
        }}
        onClose={() => setIsOpen(false)}
      />
      <Modal show={configurationOpen} onClose={() => setConfigurationOpen(false)}>
        <CameraConfiguration camera={camera} />
      </Modal>
      <Box sx={{ margin: 4 }}>
        <div className="flex justify-between">
          <Typography
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.15rem', md: '1.25rem ' },
              fontWeight: 600,
              marginBottom: 2,
              fontFamily: 'open-sans',
            }}
            gutterBottom
            component="div"
          >
            Kamerat ({site.cameras?.length || 0})
          </Typography>
          <EditNoteIcon fontSize="large" color={edit ? 'action' : 'success'} onClick={() => setEdit(!edit)} className="cursor-pointer hover:scale-110 ease-in-out duration-300" />
        </div>
        <Table size="small" aria-label="cameras">
          <TableHead>
            <TableRow sx={{ fontSize: 16 }}>
              <TableCell sx={sx}></TableCell>
              <TableCell sx={sx}>Nimi</TableCell>
              {(mediaSize.lg || mediaSize.xl) && (
                <>
                  <TableCell sx={sx} align="right">
                    Kameran ID
                  </TableCell>
                  <TableCell sx={sx} align="right">
                    IP-osoite
                  </TableCell>
                </>
              )}
              <TableCell sx={sx} align="right">
                Sijainti
              </TableCell>
              {(mediaSize.lg || mediaSize.xl) && (
                <TableCell sx={sx} align="right">
                  Lisätty
                </TableCell>
              )}
              <TableCell sx={{ ...sx }} align="center">
                PTZ <ControlCameraIcon />
              </TableCell>
              {edit && (
                <TableCell sx={sx} align="right">
                  Muokkaa
                </TableCell>
              )}
              <TableCell sx={{ fontSize: 16 }} align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {site.cameras &&
              site.cameras.map((camera: any) => (
                <TableRow key={camera.id}>
                  <TableCell>{checkIfCameraIsError(camera) ? <VideocamOffIcon sx={{ color: 'red' }} /> : <VideocamIcon sx={{ color: 'green' }} />}</TableCell>
                  <TableCell
                    sx={{
                      ...rowsx,
                      color: checkIfCameraIsError(camera) ? 'red' : 'black',
                    }}
                    component="th"
                    scope="row"
                  >
                    {camera.name}
                  </TableCell>
                  {(mediaSize.lg || mediaSize.xl) && (
                    <>
                      <TableCell
                        sx={{
                          ...rowsx,
                          color: checkIfCameraIsError(camera) ? 'red' : 'black',
                        }}
                        align="right"
                      >
                        {camera.id}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...rowsx,
                          color: checkIfCameraIsError(camera) ? 'red' : 'black',
                        }}
                        align="right"
                      >
                        {camera.ip}
                      </TableCell>
                    </>
                  )}
                  <TableCell
                    sx={{
                      ...rowsx,
                      color: checkIfCameraIsError(camera) ? 'red' : 'black',
                    }}
                    align="right"
                  >
                    {camera.location}
                  </TableCell>
                  {(mediaSize.lg || mediaSize.xl) && (
                    <TableCell
                      sx={{
                        ...rowsx,
                        color: checkIfCameraIsError(camera) ? 'red' : 'black',
                      }}
                      align="right"
                    >
                      {convertTimestamp(camera.createdAT)}
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <PtzCheckBox camera={camera} />
                  </TableCell>
                  {edit && (
                    <TableCell sx={sx} align="right">
                      <DeleteForeverIcon
                        sx={{
                          fontSize: 25,
                          color: 'red',
                        }}
                        className="cursor-pointer hover:scale-125 ease-in-out duration-300"
                        onClick={() => handleDeleteConfirmation({ id: camera.id, type: 'camera', camera: camera }) /* handleDelete({ id: camera.id, type: 'camera' }) */}
                      />
                    </TableCell>
                  )}

                  <TableCell align="right">
                    <HelpCenterIcon
                      sx={{
                        fontSize: { xs: 25, sm: 25, md: 25 },
                        color: '#43687e',
                      }}
                      className="cursor-pointer hover:scale-125 ease-in-out duration-300"
                      onClick={() => {
                        setCamera({
                          camera: {
                            password: camera.password,
                            gateway: site.rutAddress,
                            ip: camera.ip,
                          },
                        });
                        setConfigurationOpen(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {edit && (
          <div className="mt-4 w-full flex justify-end ">
            <div className="hover:scale-110 ease-in-out duration-300 ">
              <Button
                style={{
                  fontWeight: 600,
                  letterSpacing: -0.3,
                  color: 'white',
                  fontFamily: 'open-sans',
                }}
                className="  text-sm lg:text-sm font-bold py-2 px-4    rounded-2xl hover:shadow-xl"
                onClick={() => setOpenModal(true)}
                sx={{
                  fontFamily: 'poppins',
                  borderRadius: 25,
                  fontWeight: 600,
                  letterSpacing: -0.3,
                  paddingX: 3,
                  color: 'white',
                  backgroundColor: '#43687e',
                  '&:hover': {
                    backgroundColor: '#2c4f5a',
                  },
                }}
              >
                LISÄÄ KAMERA
              </Button>
            </div>
          </div>
        )}
      </Box>
    </>
  );
};

export default Cameras;
