import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/authContext';
import { getUserData, getSubscriptions } from '../../../context/apiFunctions';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Subscriptions } from './tabs/subscription';
import Profile from './tabs/billingAddress';
import UserInformation from './tabs/userInformation';
function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const fetchPaymentMethod = async ({ currentUser, paymentMethodId }: { currentUser: any; paymentMethodId: string }) => {
  const token = await currentUser.getIdToken();
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-payment-method`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ paymentMethodId: paymentMethodId }),
  });
  const data = await response.json();
  return data;
};

const fetchBillingAddress = async ({ currentUser, billingID }: { currentUser: any; billingID: string }) => {
  const token = await currentUser.getIdToken();
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-address`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },

    body: JSON.stringify({ billingID: billingID }),
  });
  const data = await response.json();
  return data;
};

const MyAccount = () => {
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState<any>([]);
  const [subcriptions, setSubscriptions] = useState<any>([]);
  const [value, setValue] = React.useState(0);
  const [billingAddress, setBillingAddress] = useState<any>([]);
  const [paymentMethod, setPaymentMethod] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const updateCard = async () => {
    const billingAddress = await fetchBillingAddress({
      currentUser: currentUser,
      billingID: profile.billingID,
    });
    setBillingAddress(billingAddress);
    const paymentMethod = await fetchPaymentMethod({
      currentUser: currentUser,
      paymentMethodId: billingAddress.invoice_settings.default_payment_method,
    });

    setPaymentMethod(paymentMethod);
    setOpenModal(false);
  };

  const updateData = async () => {
    const subscriptions = await getSubscriptions({
      currentUser: currentUser,
      billingID: profile.billingID,
    });
    setSubscriptions(subscriptions);
  };

  useEffect(() => {
    async function fetchProfile() {
      if (!currentUser) {
        return;
      }
      const user = await getUserData({ currentUser: currentUser });
      if (!user) {
        return;
      }

      if (user.billingCustomerId && user.role === 'USER') {
        setProfile(user);
      }

      setProfile(user);
      if (!user.billingID) {
        return;
      }
      if (user && user.billingID) {
        const billingAddress = await fetchBillingAddress({
          currentUser: currentUser,
          billingID: user.billingID,
        });
        const subscriptions = await getSubscriptions({
          currentUser: currentUser,
          billingID: user.billingID,
        });
        const paymentMethod = await fetchPaymentMethod({
          currentUser: currentUser,
          paymentMethodId: billingAddress.invoice_settings.default_payment_method,
        });

        setPaymentMethod(paymentMethod);
        setBillingAddress(billingAddress);
        setSubscriptions(subscriptions);
        // Fetch user subscriptions
      }
    }
    fetchProfile();
  }, [currentUser]);
  console.log(profile);
  return (
    <div className="my-20 bg-white w-4/5 rounded-2xl ">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Minun tiedot" {...a11yProps(0)} />
            <Tab label="Subscriptions" {...a11yProps(1)} />
            {/*    <Tab label="Receipts" {...a11yProps(2)} /> */}
            <Tab label="Settings" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {/* {profile.billingCustomerId && profile.role === 'USER' ? <UserInformation profile={profile} /> : <div>loading...</div>} */}
          <UserInformation profile={profile} />
          {/* {profile.billingID && <Profile billingAddress={billingAddress} paymentMethod={paymentMethod} updateCard={updateCard} openModal={openModal} setOpenModal={setOpenModal} />} */}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Subscriptions subcriptions={subcriptions} updateData={updateData} />
        </CustomTabPanel>
        {/*    <CustomTabPanel value={value} index={2}>
          Item Three
        </CustomTabPanel> */}
        <CustomTabPanel value={value} index={3}>
          Item Three
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default MyAccount;
