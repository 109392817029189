import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { LogOut } from 'lucide-react';

import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AddHomeIcon from '@mui/icons-material/AddHome';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from 'react-responsive';
import MobileNavBar from './navbar/mobileNavBar';
import { DashboardLink, handleDashboardClick } from './sidebar/dashboardlink';

export type DashboardLinks = {
  to: string;
  text: string;
  icon: JSX.Element;
};

const dashboardLinks = [
  { to: '/dashboard', text: 'Hallintapaneeli', icon: <MapsHomeWorkIcon />, role: ['USER', 'ADMIN'] },
  { to: '/addNewCustomer', text: 'Uusi kohde', icon: <AddHomeIcon />, role: ['USER', 'ADMIN'] },
  // { to: '/guides', text: 'Ohjeet', icon: <AutoStoriesIcon /> },
  { to: '/myAccount', text: 'Minun tili', icon: <ManageAccountsIcon /> },
] as DashboardLinks[];

const iconSize = {
  sm: 20,
  md: 20,
  lg: 25,
  xl: 25,
};

function Sidebar() {
  const { logout, currentUser, verifiedUser, user } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 570 });
  const isNotSmallScreen = useMediaQuery({ minWidth: 700 });
  const location = useLocation();
  const dashboardHoverTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const menuTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleDashboardEnter = () => {
    if (dashboardHoverTimer.current) {
      clearTimeout(dashboardHoverTimer.current);
    }
    setShowMenu(true);
  };
  const handleMenuEnter = () => {
    if (menuTimer.current) {
      clearTimeout(menuTimer.current);
    }
    setIsMenuOpen(true);
  };

  const handleMenuLeave = () => {
    menuTimer.current = setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  const handleDashboardLeave = () => {
    dashboardHoverTimer.current = setTimeout(() => {
      setShowMenu(false);
    }, 1000); // 1-second delay for hiding the dashboard menu
  };

  function handleLogout() {
    logout();
    navigate('/login');
  }

  const isActive = dashboardLinks.some((link) => link.to === location.pathname);

  if (isMobile) {
    return <MobileNavBar dashboardLinks={dashboardLinks} user={user} logout={logout} />;
  }
  const isAdmin = user?.role === 'ADMIN';
  const isReseller = user?.role === 'RESELLER';
  const isUser = user?.role === 'USER';
  const isBillingCustomer = user?.role === 'BILLINGCUSTOMER';
  return (
    <div className="font-poppins tracking-one text-white font-medium sticky top-0 flex py-3 z-50 justify-evenly" style={{ zIndex: 9999 }}>
      <div className="w-1/6"></div>
      <div className="flex items-center gap-4">
        <DashboardLink to="/home" text="Etusivu" icon={<HomeIcon sx={{ fontSize: iconSize }} />} />
        <DashboardLink to="/about" text="Tietoa meistä" icon={<HomeIcon sx={{ fontSize: iconSize }} />} />

        {/* Conditional links based on user role */}
        {isReseller && <DashboardLink to="/reseller" text="Hallintapaneeli" icon={<MapsHomeWorkIcon />} />}
        {isAdmin && <DashboardLink to="/admin" text="Adminpaneeli" icon={<MapsHomeWorkIcon />} />}

        {/* Dashboard dropdown */}
        {currentUser && verifiedUser && (isUser || isBillingCustomer || isAdmin) && (
          <div className="relative" onMouseOver={handleDashboardEnter} onMouseLeave={handleDashboardLeave}>
            <button className={`${isActive ? 'text-activeTab' : ''} text-xs lg:text-base hover:bg-slate-700 items-center`}>
              <ArrowDropDownIcon /> HALLINTAPANEELI
            </button>
            {showMenu && (
              <div className="absolute bg-white rounded-xl right-0 shadow-md mt-2 py-0 flex flex-col gap-2 text-almostBlack" style={{ zIndex: 9999 }}>
                {dashboardLinks.map((link, index) => handleDashboardClick(link, index, setShowMenu))}
              </div>
            )}
          </div>
        )}

        <DashboardLink to="/contact" text="Ota yhteyttä" icon={<HomeIcon sx={{ fontSize: iconSize }} />} />

        {/* User Menu (Login/Logout) */}
        <UserActionMenu
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          currentUser={currentUser}
          handleLogout={handleLogout}
          isNotSmallScreen={isNotSmallScreen}
          handleMenuLeave={handleMenuLeave}
          handleMenuEnter={handleMenuEnter}
        />
      </div>
    </div>
  );
}

export default Sidebar;

const UserActionMenu = ({
  isMenuOpen,
  setIsMenuOpen,
  currentUser,
  handleLogout,
  isNotSmallScreen,
  handleMenuLeave,
  handleMenuEnter,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
  currentUser: any;
  handleLogout: () => void;
  isNotSmallScreen: boolean;
  handleMenuLeave: () => void;
  handleMenuEnter: () => void;
}) => {
  return (
    <div className="relative">
      <MenuIcon onClick={() => setIsMenuOpen(!isMenuOpen)} />
      <div className={`${isMenuOpen ? 'block' : 'hidden'} absolute top-0 right-0 mt-10 bg-white text-almostBlack text-center w-72 shadow-lg rounded-lg`}>
        <div onMouseLeave={() => handleMenuLeave()} onMouseEnter={() => handleMenuEnter()} className="flex flex-col justify-center items-center gap-2 p-10">
          {currentUser ? (
            <button onClick={handleLogout} className="flex text-xs lg:text-base items-center hover:scale-110 transition-transform duration-300">
              <LogOut size={24} />
              {isNotSmallScreen && 'Kirjaudu ulos'}
            </button>
          ) : (
            <>
              <DashboardLink to="/login" text="Kirjaudu sisään" />
              {/*   <DashboardLink to="/register" text="Rekisteröidy" icon={<HomeIcon sx={{ fontSize: iconSize }} />} /> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
