import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/authContext';

const RequireAuth = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const { user, currentUser, loading } = useAuth();
  const location = useLocation();

  if (currentUser && !user) {
    return <div>Ladataan käyttäjätietoja...</div>; // Näytä latausindikaattori tai muu placeholder
  }
  return allowedRoles.includes(user?.role) ? (
    <Outlet /> // Jos käyttäjän rooli on sallittujen joukossa, näytetään lapsikomponentit
  ) : user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace /> // Jos käyttäjä on kirjautunut sisään, mutta ei oikeaa roolia, ohjataan unauthorized-sivulle
  ) : (
    <Navigate to="/login" state={{ from: location }} replace /> // Jos käyttäjä ei ole kirjautunut sisään, ohjataan kirjautumissivulle
  );
};

export default RequireAuth;
